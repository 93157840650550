import { Link } from "gatsby";
import React, { ReactNode } from "react";
import "../../styles/globals.scss";
import { Copyright } from "../Parts/copyright";
import { Logo } from "../Parts/logo";
import { StepCount } from "../Parts/stepCount";
import { Title } from "../Parts/title";
import * as styles from "./layoutApp.module.scss";
// import "swiper/swiper.css";

interface Props {
  children: ReactNode;
  title: string;
  step: number;
}

export const LayoutApp: React.FC<Props> = ({ children, title, step }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.flexCed}>
        <div className={styles.topPageWrapper}>
          <div className={styles.logoWrapper}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <StepCount step={step} />
        </div>
        <div className={styles.titleWrapper}>
          <Title content={title} />
        </div>
        {children}
        <div className={styles.copyright}>
          <Copyright />
        </div>
      </div>
    </div>
  );
};

import { graphql, navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { LayoutApp } from "../components/Layout/layoutApp";
import { Button } from "../components/Parts/button";
import * as styles from "../styles/app.module.scss";
import * as parts from "../components/Parts/parts.module.scss";
import SEO from "../components/seo";
import { Location } from "./message";
import { Modal } from "../components/Modal/modal";
import { SmartBackButton } from "../components/Parts/smartBackButton";
import { usePageContext } from "../components/Context/pageContext";

// const ES = "https://tours-ecard.soixanteseize.tech/";

const Send: React.FC<Location & any> = ({ location, data: { send } }) => {
  const modalRef1 = useRef();
  const { lang } = usePageContext();
  const [result, setResult] = useState<any>(undefined);
  const [action, setAction] = useState<any>({});

  useEffect(() => {
    if (!location.state) navigate("/");
    setResult(location?.state?.res);
  }, []);

  return (
    <>
      <LayoutApp title={send.content[lang]} step={3}>
        <SEO
          title="Envoi"
          links={[
            {
              rel: "preconnect",
              href: `https://res.cloudinary.com`,
            },
            {
              rel: "dns-prefetch",
              href: `https://res.cloudinary.com`,
            },
            {
              rel: "preconnect",
              href: `https://www.facebook.com/`,
            },
            {
              rel: "dns-prefetch",
              href: `https://www.facebook.com/`,
            },
            {
              rel: "preconnect",
              href: `https://twitter.com/`,
            },
            {
              rel: "dns-prefetch",
              href: `https://twitter.com/`,
            },
            action.action === "facebook"
              ? {
                  rel: "prefetch",
                  href: result.videoUrl + ".gif",
                  // href: `https://www.facebook.com/sharer/sharer.php${objectToGetParams(
                  //   {
                  //     u: result.shareUrl,
                  //   }
                  // )}`,
                }
              : {},
            action.action === "twitter"
              ? {
                  rel: "prefetch",
                  href: `https://twitter.com/share${objectToGetParams({
                    url: result.shareUrl,
                  })}`,
                }
              : {},
            result
              ? {
                  rel: "prefetch",
                  href: `${result.videoUrl}.mp4`,
                  as: "video",
                  type: "video/mp4",
                }
              : {},
          ]}
        />
        <div className={styles.visualizeButton}>
          {result && (
            <>
              {result === "error" ? (
                <button>Erreur</button>
              ) : (
                <button onClick={() => modalRef1.current.openModal()}>
                  Visualisez le gif
                </button>
              )}
              <Modal ref={modalRef1}>
                <div className={styles.iframeWrapper}>
                  <video className={styles.iframe} muted autoPlay={true}>
                    <source src={`${result?.videoUrl}.mp4`} type="video/mp4" />
                    <source
                      src={`${result?.videoUrl}.webm`}
                      type="video/webm"
                    />
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                </div>
              </Modal>
            </>
          )}
          {!result && <button>Chargement...</button>}
        </div>

        <div className={styles.shareWrapper}>
          <div>
            <button
              onClick={() =>
                setAction({
                  action: "facebook",
                  url: result?.videoUrl + ".gif",
                })
              }
            >
              <div
                className={`${styles.card} ${
                  action?.action !== "facebook" && parts.greyedOut
                }`}
              >
                <img src="/Facebook.svg" />
              </div>
            </button>
            <div className={styles.text}>Partagez via Facebook</div>
          </div>
          <div>
            <button
              onClick={() =>
                setAction({ action: "twitter", url: result?.shareUrl })
              }
            >
              <div
                className={`${styles.card} ${
                  action?.action !== "twitter" && parts.greyedOut
                }`}
              >
                <img src="/Twitter.svg" />
              </div>
            </button>
            <div className={styles.text}>Partagez via Twitter</div>
          </div>
          <div>
            <button
              onClick={() =>
                setAction({ action: "download", url: result?.downloadUrl })
              }
            >
              <div
                className={`${styles.card} ${
                  action?.action !== "download" && parts.greyedOut
                }`}
              >
                <img src="/Download.svg" />
              </div>
            </button>
            <div className={styles.text}>Télécharger la carte</div>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <SmartBackButton
            path="/message"
            state={{
              text: location.state?.text,
              name: location.state?.name,
              color: location.state?.color,
              poster: location.state?.poster,
              slide: location.state?.slide,
            }}
          />
          <Button
            content={send.button[lang]}
            destination="/bravo"
            clickable={Boolean(action.action)}
            state={{ [action.action]: action.url }}
          />
        </div>
      </LayoutApp>
    </>
  );
};

export default Send;

export const query = graphql`
  query send {
    send: sanityApp {
      button: sendButton {
        en
        fr
      }
      content: sendContent {
        en
        fr
      }
    }
  }
`;

const objectToGetParams = (object: {
  [key: string]: string | number | undefined | null;
}) => {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join("&")}` : "";
};

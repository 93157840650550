import React from "react";
import * as styles from "./parts.module.scss";

interface Props {
  step: number;
}

export const StepCount: React.FC<Props> = ({ step }) => {
  const steps = [1, 2, 3];
  return (
    <div className={styles.stepCount}>
      {steps.map((index: number) => (
        <React.Fragment key={`step-${index}`}>
          <span
            key={`step-${index}`}
            className={(index === step && styles.colored) || undefined}
          >
            {index}
          </span>
          {index < steps.length && <span> &nbsp;-&nbsp; </span>}
        </React.Fragment>
      ))}
    </div>
  );
};

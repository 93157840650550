import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Portal } from "../Portal/portal";
import * as styles from "./modal.module.scss";

export const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose(),
    };
  });

  const handleOpen = () => {
    setDisplay(true);
  };

  const handleClose = () => {
    setDisplay(false);
  };

  if (display) {
    return (
      <Portal>
        <div className="modal-backdrop"></div>
        <div className={styles.modalContainer}>
          {props.children}
          <button onClick={handleClose}>Fermer</button>
        </div>
      </Portal>
    );
  }

  return null;
});

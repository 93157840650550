// extracted by mini-css-extract-plugin
export var buttonsWrapper = "app-module--buttonsWrapper--m8c0C";
export var card = "app-module--card--eIbU1";
export var carouselWrapper = "app-module--carouselWrapper--ECnNT";
export var clearWrapper = "app-module--clearWrapper--6BcpV";
export var contentWrapper = "app-module--contentWrapper--qw2S5";
export var formWrapper = "app-module--formWrapper--j3BAL";
export var iframe = "app-module--iframe--lQJZz";
export var iframeWrapper = "app-module--iframeWrapper--VZyQZ";
export var input = "app-module--input--+jvGS";
export var pickerSwiper = "app-module--pickerSwiper--Apvxv";
export var sendWrapper = "app-module--sendWrapper--OBCJS";
export var shareWrapper = "app-module--shareWrapper--ycF7x";
export var subTitle = "app-module--subTitle--of2H6";
export var text = "app-module--text--eoLp4";
export var textColorWrapper = "app-module--textColorWrapper--Gjqov";
export var textarea = "app-module--textarea--OZdu+";
export var visualizeButton = "app-module--visualizeButton--jhflL";